import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import PostTemplateDetails from '../components/PostTemplateDetails'
import Comments from '../components/Comments/Comments'
import { SEO } from '../components/SEO'

class PostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark
    const { title, description } = post.frontmatter

    return (
      <Layout>
        <React.Fragment>
          <SEO pageTitle={title} pageDescription={description} />
          <PostTemplateDetails {...this.props} />
          <Comments />
        </React.Fragment>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query PostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        author {
          name
          twitter
        }
        disqusShortname
        url
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      tableOfContents
      fields {
        tagSlugs
        slug
      }
      frontmatter {
        title
        tags
        date
        description
        toc
        wip
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            tagSlugs
            slug
          }
          frontmatter {
            title
            tags
            date
            description
          }
        }
      }
    }
  }
`
