import React from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'
import './style.scss'

export default class SocialShare extends React.Component {
  render() {
    const iconSize = 32
    let { url = '', title = '' } = this.props
    let sharedTitle = `Check this blog post by @trungvose`
    return (
      <div
        className="social-share-container"
        title="If you like it, share it!!"
      >
        <FacebookShareButton
          className="social-icon"
          url={url}
          quote={sharedTitle}
        >
          <FacebookIcon size={iconSize} round />
        </FacebookShareButton>
        <TwitterShareButton
          className="social-icon"
          url={url}
          title={sharedTitle}
        >
          <TwitterIcon size={iconSize} round></TwitterIcon>
        </TwitterShareButton>
        <LinkedinShareButton
          className="social-icon"
          url={url}
          title={sharedTitle}
        >
          <LinkedinIcon size={iconSize} round></LinkedinIcon>
        </LinkedinShareButton>
      </div>
    )
  }
}
