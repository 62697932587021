import { Link } from 'gatsby'
import { BlogPostJsonLd } from 'gatsby-plugin-next-seo'
import moment from 'moment'
import React from 'react'
import SocialShare from '../SocialShare'
import RecentPosts from '../RecentPosts'
import './style.scss'

const MAX_RECENT_POSTS = 5

class PostTemplateDetails extends React.Component {
  render() {
    const { author, url } = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const {
      title: postTitle,
      date: postDate,
      description: postDescription,
      toc,
      wip,
    } = post.frontmatter
    const { slug } = post.fields
    const recentPosts = this.getRecentPosts(slug)
    const tags = post.fields.tagSlugs
    const postUrl = `${url}${post.fields.slug}`

    const tagsBlock = (
      <div className="post-single__tags">
        <ul className="post-single__tags-list">
          {tags &&
            tags.map((tag, i) => (
              <li className="post-single__tags-list-item" key={tag}>
                <Link to={tag} className="post-single__tags-list-item-link">
                  {post.frontmatter.tags[i]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    )

    const tocTemplate = (
      <nav role="navigation" className="post-single__body post-single__toc">
        <div
          className="post-single__toc-table"
          dangerouslySetInnerHTML={{
            __html: post.tableOfContents,
          }}
        ></div>
      </nav>
    )

    const wipTemplate = (
      <div className="post-single__wip">
        <p>⚠️ {wip}</p>
      </div>
    )

    return (
      <React.Fragment>
        <BlogPostJsonLd
          url={postUrl}
          title={postTitle}
          datePublished={postDate}
          authorName={author}
          description={postDescription}
        />
        <div className="post-single">
          <div className="post-single__inner">
            <h1 className="post-single__title">{postTitle}</h1>
            {toc && tocTemplate}
            {wip && wipTemplate}
            <div
              className="post-single__body"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            <div className="post-single__date">
              <em>Published {moment(postDate).format('D MMM YYYY')}</em>
            </div>
            <div className="post-single__date">
              <SocialShare url={postUrl} title={postTitle}></SocialShare>
            </div>
          </div>
          <div className="post-single__footer">
            {tagsBlock}
            <hr />
            <RecentPosts posts={recentPosts}></RecentPosts>
          </div>
        </div>
      </React.Fragment>
    )
  }

  getRecentPosts(slug) {
    let posts = [...this.props.data.allMarkdownRemark.edges].reverse()
    let postIndex = posts
      .reverse()
      .findIndex(({ node }) => node.fields.slug === slug)
    const recentPosts = posts
      .slice(postIndex, postIndex + MAX_RECENT_POSTS + 1)
      .filter(({ node }) => node.fields.slug !== slug)

    if (recentPosts.length === MAX_RECENT_POSTS) {
      return recentPosts
    }
    return posts
      .slice(posts.length - (MAX_RECENT_POSTS + 1), posts.length)
      .filter(({ node }) => node.fields.slug !== slug)
  }
}

export default PostTemplateDetails
