import React, { Component } from 'react'
import Post from '../Post'
export default class RecentPosts extends Component {
  render() {
    let { posts } = this.props
    return (
      <React.Fragment>
        <h3>Read more</h3>
        {posts.map(post => (
          <Post data={post} key={post.node.fields.slug} />
        ))}
      </React.Fragment>
    )
  }
}
